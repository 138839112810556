.mask-container {
  position: fixed; }

.mask-container .mask {
  position: absolute; }

.mask-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow: hidden; }
  .mask-container .mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0; }
    .mask-container .mask.fade-in {
      opacity: 0.3; }
  .mask-container .content {
    /* height: 100%;
    width: 100%; */
    /* position: absolute;
    z-index: 2; */
  }

 .h5-root .ant-select-dropdown {

  left: .33rem !important;
}
.ant-select-dropdown {
  width: 3.06rem !important;
}

.slide-page-container, .slide-page-container .close-container {
  position: absolute; }

.slide-page-container .close-container .slide-page-close {
  position: relative; }

.slide-page-container .close-container .slide-page-close {
  display: block; }

.slide-page-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  overflow: hidden; }
  .slide-page-container .close-container {
    right: 0;
    top: 0; }
    .slide-page-container .close-container .slide-page-close {
      right: 0.14rem;
      top: 0.12rem;
      z-index: 9;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      font-size: 0.5rem !important;
      font-weight: normal;
      color: #FF6666;
      padding-top: 0.15rem;
      padding-right: 0.15rem;
      padding-bottom: 0.15rem;
      padding-left: 0.15rem;
      padding-top: 0; }
  .slide-page-container.info {
    background-color: #fff; }
  .slide-page-container.root {
    background-color: #f5f5f6; }
  .slide-page-container.right {
    transform: translateX(0);
    -webkit-transform: translateX(0); }
    .slide-page-container.right.hide {
      transform: translateX(300%);
      -webkit-transform: translateX(300%); }
  .slide-page-container.down {
    transform: translateY(0);
    -webkit-transform: translateY(0); }
    .slide-page-container.down.hide {
      transform: translateY(300%);
      -webkit-transform: translateY(300%); }
  .slide-page-container.left {
    transform: translateX(0);
    -webkit-transform: translateX(0); }
    .slide-page-container.left.hide {
      transform: translateX(-300%);
      -webkit-transform: translateX(-300%); }
  .slide-page-container.up {
    transform: translateY(0);
    -webkit-transform: translateY(0); }
    .slide-page-container.up.hide {
      transform: translateY(-300%);
      -webkit-transform: translateY(-300%); }


.logo {
  float: left;
}
.layout {
  height: 100%;
  overflow: scroll;
}

.ant-layout-content {
  flex: none;
  height: auto
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-submenu-horizontal > .ant-menu-submenu:hover,
.ant-menu-submenu-title:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #E94F19 !important;
  border-color: #E94F19 !important;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-input-placeholder { /* WebKit browsers */
　color: #fff !important
}

body,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
img,
legend,
li,
ol,
p,
ul {
    margin: 0;
    padding: 0;
}

fieldset,
img {
    border: none;
}

address,
caption,
cite,
code,
dfn,
th,
var {
    font-style: normal;
    font-weight: normal;
}

ol,
ul {
    list-style: none;
}

input, textarea {
    outline: none !important;
    border: none;
}
a {
  text-decoration: none;
}
body,
html {
  width: 100%;
  height: 100%;
  font-size: .28rem;
}

html {
  overflow-x: hidden;
}

body {
  
  text-rendering: opximizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: PingFangSC-Regular, verdana, Arial !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, Arial, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wp100 {
  width: 100%;
}

.hand-animate {
  -webkit-animation-name: hand;
          animation-name: hand;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  position: absolute;
  width: .6rem;
  top: 3.5rem;
  left: 2.5rem
}
.scale-big {
  -webkit-animation-name: big;
          animation-name: big;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}
@-webkit-keyframes big {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0)
  }
  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1)
  }
  95% {
    -webkit-transform: scale(.95);
            transform: scale(.95)
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1)
  }
}
@-webkit-keyframes hand {
    0% {
      top: 3.5rem;
      opacity: 1
    }
    100% {
      top: 1.5rem;
      opacity: .3
    }
}
input::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #a0a0a0;
  /* placeholder字体大小  */
  font-size: .15rem;
}
.juanxinhao-edit::-webkit-input-placeholder {
  font-size: .1rem
}
.cai-name::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #fb0;
  /* placeholder字体大小  */
  font-size: .05rem;
  line-height: .07rem
}

img.food-name-1 {
  width: .4rem !important;
}

img.food-name-5 {
  width: .5rem !important;
}

img.food-name-2 {
    width: .5rem !important;
}
img.food-pic-7 {
  position: absolute !important;
  width: 0.8rem !important;
  top: 0.077rem !important;
  height: 0.67rem !important;
  right: 0.332rem !important;
}
img.food-pic-5 {
  position: absolute !important;
  width: 0.765rem !important;
  top: 0.08rem !important;
  height: 0.645rem !important;
  left: 0.348rem !important;
}
img.food-pic-8 {
    top: .063rem !important;
    right: .325rem !important;
    width: 0.815rem !important;
    height: 0.69rem !important;
}
img.food-pic-1 {
  top: .057rem !important;
  right: .33rem !important;
  width: 0.8rem !important;
  height: 0.68rem !important;
}
img.food-pic-10 {
  top: .042rem !important;
  left: 0.326rem !important;
  width: 0.82rem !important;
  height: 0.7rem !important;
}
.am-toast-notice {
  -webkit-transform: scale(1.2);
          transform: scale(1.2)
}
.slider-frame {
  height: 2.09rem !important
}
.slider-decorator-0 {
  bottom: -.2rem !important
}

.ant-select-selection {
  border: 0;
  background: transparent;
}
.ant-select-selection .ant-select-selection__rendered {
  margin: 0 .1rem
}

.ant-select-selection .ant-select-selection__placeholder,
.ant-select-selection .ant-select-search__field__placeholder {
  color: #a0a0a0;
  font-size: .12rem;
}

.ant-select-selection-selected-value {
  font-size: .12rem;
}

.am-progress-bar {
  height: .04rem !important
}
.ant-select-open .ant-select-selection,
.ant-select-focused .ant-select-selection {
  box-shadow: none
}
.ant-modal {
  width: 10rem !important
}

